import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const Layout = () => {
  const [activeSection, setActiveSection] = useState('home');

  const navItems = [
    { id: 'home', label: 'Home' },
    { id: 'projects', label: 'Projects' },
    { id: 'reading', label: 'Reading' },
    { id: 'writing', label: 'Writing' },
    { id: 'technical-writing', label: 'Technical Writing' },
  ];


  const contentMap = {
    home: (
      <div className="space-y-8">
        <section>
          <p className="text-[16px] leading-relaxed">
            I've spent the last few years at the <a href="https://www.gatech.edu/" target="_blank" rel="noopener noreferrer" className="text-[#b319ab] hover:italic transition-all">Georgia Institute of Technology</a> studying Computer Science. Broadly, I'm interested in reasoning about the world around us. For me, this manifests in several ways:
          </p>
        </section>

        <section>
          <h2 className="text-[25px] font-light mb-4">Work</h2>
          <p className="text-[16px] leading-relaxed">
            Currently, I study the applications of machine learning methods to science, focusing on biological contexts, with <a href="https://amirmohan.github.io/" target="_blank" rel="noopener noreferrer" className="text-[#b319ab] hover:italic transition-all">Amirali Aghazadeh</a>. In the past, I have worked on:
          </p>
          <ul className="list-disc text-[16px] leading-relaxed pl-5 space-y-2 mt-2">
            <li>Partner Integrations at <a href="https://www.box.com/" target="_blank" rel="noopener noreferrer" className="text-[#b319ab] hover:italic transition-all">Box</a></li>
            <li>Decision Making for Autonomous Robots with <a href="https://www.me.gatech.edu/faculty/zhao" target="_blank" rel="noopener noreferrer" className="text-[#b319ab] hover:italic transition-all">Ye Zhao</a></li>
          </ul>
        </section>

        <section>
          <h2 className="text-[23px] font-light mb-4">Not Work</h2>
          <ul className="list-disc text-[16px] leading-relaxed pl-5 space-y-4">
            <li>I try to spend a lot of my free time reading. I think most questions you could have in life have been asked and answered many times before, and I try to find answers of my own whether it's in history books, biographies, fiction, or even academic papers/textbooks.</li>
            <li>One day I hope to teach when I am able. Few things are more beautiful than helping impart knowledge to someone that is hungry for it.</li>
            <li>What is the brain, really? Are we just functions generated by hundreds of years of evolution, fine-tuned by our lived experience?</li>
            <li>I have always believed in the existence of a soul, something more than our base instincts. Can we rigorously prove that such a thing exists?</li>
          </ul>
        </section>

        {/* <section>
          <h2 className="text-[23px] font-light mb-4">What I'm Reading</h2>
          <p className="text-[16px] leading-relaxed">
            I'm currently reading:
          </p>
        </section> */}
      </div>
    ),
    projects: (
      <div className="space-y-8">
        <section>
          <div className="space-y-6">
            <ul className="list-none space-y-4">
            <li>
                <a 
                  href="https://www.box.com/partners/zoom" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="block group"
                >
                  <h3 className="text-xl text-black group-hover:text-[#b319ab] group-hover:italic transition-all flex items-center">
                    Box for Zoom
                    <span className="ml-2 w-2 h-2 bg-[#b319ab] rounded-full inline-block"></span>
                  </h3>
                  <p className="text-gray-600 text-sm">
                    Designed and built cloud recordings for Box's Zoom integration
                  </p>
                </a>
              </li>
              <li>
                <a 
                  href="https://trynook.vercel.app" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="block group"
                >
                  <h3 className="text-xl text-black group-hover:text-[#b319ab] group-hover:italic transition-all flex items-center">
                    Nook
                    <span className="ml-2 w-2 h-2 bg-[#b319ab] rounded-full inline-block"></span>
                  </h3>
                  <p className="text-gray-600 text-sm">
                    Find places just like your favorites
                  </p>
                </a>
              </li>
              <li>
                <a 
                  href="https://github.com/mushster/AeroForecast" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="block group"
                >
                  <h3 className="text-xl text-black group-hover:text-[#b319ab] group-hover:italic transition-all">AeroForecast</h3>
                  <p className="text-gray-600 text-sm">A system that predicts flight delays using machine learning</p>
                </a>
              </li>
              
              <li>
                <a 
                  href="https://github.com/mushster/wiki-explorer" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="block group"
                >
                  <h3 className="text-xl text-black group-hover:text-[#b319ab] group-hover:italic transition-all">WikiLens</h3>
                  <p className="text-gray-600 text-sm">An easy way to rabbit hole through Wikipedia</p>
                </a>
              </li>
              <li>
                <a 
                  href="https://devpost.com/software/catapult-rxlsq0" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="block group"
                >
                  <h3 className="text-xl text-black group-hover:text-[#b319ab] group-hover:italic transition-all">Catapult</h3>
                  <p className="text-gray-600 text-sm">Connecting Small Businesses with Venture Capital</p>
                </a>
              </li>
            </ul>
          </div>
        </section>
      </div>
    ),
    writing: <p className="text-lg leading-relaxed">Coming soon!</p>,
    'technical-writing': <p className="text-lg leading-relaxed">Coming soon!</p>,
    reading: (
      <div className="space-y-8">
        <section>
          <div className="space-y-6">
            <div>
              <ul className="list-disc text-[16px] leading-relaxed pl-5 space-y-2">
                <li>
                  <span className="text-black">The Beginning of Infinity</span>
                  <span className="text-gray-600 ml-2">by David Deutsch</span>
                </li>
                <li>
                  <span className="text-black">Gödel, Escher, Bach</span>
                  <span className="text-gray-600 ml-2">by Douglas Hofstadter</span>
                </li>
                <li>
                  <span className="text-black">Superintelligence: Paths, Dangers, Strategies</span>
                  <span className="text-gray-600 ml-2">by Nick Bostrom</span>
                </li>
                <li>
                  <span className="text-black">What is Life?</span>
                  <span className="text-gray-600 ml-2">by Erwin Schrödinger</span>
                </li>
                <li>
                  <span className="text-black">Structure and Interpretation of Computer Programs</span>
                  <span className="text-gray-600 ml-2">by Abelson & Sussman</span>
                </li>
                <li>
                  <span className="text-black">Code: The Hidden Language of Computer Hardware and Software</span>
                  <span className="text-gray-600 ml-2">by Charles Petzold</span>
                </li>
                <li>
                  <span className="text-black">Programming Pearls</span>
                  <span className="text-gray-600 ml-2">by Jon Bentley</span>
                </li>
                <li>
                  <span className="text-black">The Glass Bead Game</span>
                  <span className="text-gray-600 ml-2">by Hermann Hesse</span>
                </li>
                <li>
                  <span className="text-black">Collected Fictions</span>
                  <span className="text-gray-600 ml-2">by Jorge Luis Borges</span>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    ),
  };

  return (
    <div className="min-h-screen flex flex-col bg-white overflow-x-hidden font-serif">
      <Helmet>
        <title>Aryan Musharaf</title>
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" href="/logo192.png" />
        <meta name="theme-color" content="#ffffff" />
      </Helmet>
      
      <div className="flex-grow flex items-start pt-20 px-2"> 
        <div className="flex flex-grow max-w-5xl mx-auto w-full">
          {/* Left column with main content */}
          <main className="w-3/4 pr-8">
            {activeSection === 'home' && (
              <h1 className="text-3xl sm:text-4xl md:text-5xl font-light mb-12 tracking-wide">
                Aryan Musharaf
              </h1>
            )}
            <div className="prose prose-sm sm:prose-base space-y-4">
              {contentMap[activeSection]}
            </div>
          </main>

          {/* Right column with navbar */}
          <nav className="w-1/4 pt-8">
            <ul className="space-y-2">
              {navItems.map((item) => (
                <li key={item.id}>
                  <button
                    onClick={() => setActiveSection(item.id)}
                    className={`text-lg transition-colors focus:outline-none w-full text-left
                      ${activeSection === item.id
                        ? 'text-black font-semibold'
                        : 'text-gray-400 hover:text-gray-600'
                      }`}
                  >
                    {item.label}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>

      {/* Footer with Social Links */}
      <footer className="mt-auto flex justify-center space-x-4 py-6 px-2">
        <a href="https://x.com/aryanmusharaf" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-[#b319ab] hover:italic transition-all">
          X
        </a>
        <a href="mailto:aryanmusharaf11@gmail.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-[#b319ab] hover:italic transition-all">
          Email
        </a>
        <a href="https://linkedin.com/in/aryanmusharaf" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-[#b319ab] hover:italic transition-all">
          LinkedIn
        </a>
        <a href="https://github.com/mushster" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-[#b319ab] hover:italic transition-all">
          GitHub
        </a>
      </footer>
    </div>
  );
};

export default Layout;